import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-064efb55"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-button-group" }
const _hoisted_2 = { class: "auth-button-group__buttons-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "auth-button-group__registration",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToRegistration()))
      }, _toDisplayString(this.translateData.register), 1),
      _createElementVNode("div", {
        class: "auth-button-group__login",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.modalOpen && _ctx.modalOpen(...args)))
      }, _toDisplayString(this.translateData.to_dashboard), 1)
    ]),
    (_ctx.isModalOpen)
      ? (_openBlock(), _createBlock(_component_Popup, {
          key: 0,
          "on-close": _ctx.handleCloseModal
        }, null, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}