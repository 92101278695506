import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c64f00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lang__wrap" }
const _hoisted_2 = {
  key: 0,
  class: "lang__line"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "lang__title" }
const _hoisted_5 = {
  key: 2,
  class: "lang__lang-select"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectIcon = _resolveComponent("SelectIcon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type == 'button')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langOption, (lang) => {
            return (_openBlock(), _createElementBlock("div", {
              key: lang.id,
              onClick: ($event: any) => (_ctx.changeLang(lang)),
              class: _normalizeClass(['lang__line-button', lang == _ctx.selectLang ? 'active' : ''])
            }, _toDisplayString(lang), 11, _hoisted_3))
          }), 128))
        ]))
      : _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "lang__lang",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openLangBody()))
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(this.selectLang), 1),
          _createVNode(_component_SelectIcon, { class: "lang__svg" })
        ])), [
          [_directive_click_outside, _ctx.closeLangBody]
        ]),
    (this.langBody)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langOption, (lang) => {
            return (_openBlock(), _createElementBlock("div", {
              key: lang.id
            }, [
              (lang !== this.selectLang)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    onClick: ($event: any) => (_ctx.changeLang(lang))
                  }, _toDisplayString(lang), 9, _hoisted_6))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}