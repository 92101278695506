import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b050cb72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__burger" }
const _hoisted_3 = {
  key: 0,
  class: "header__nav"
}
const _hoisted_4 = {
  key: 1,
  class: "header__mob-menu"
}
const _hoisted_5 = { class: "header__mob-menu-container" }
const _hoisted_6 = { class: "header__mob-menu-content" }
const _hoisted_7 = { class: "header__mob-menu-lang" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_LogoWhite = _resolveComponent("LogoWhite")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Lang = _resolveComponent("Lang")!
  const _component_UserIcon = _resolveComponent("UserIcon")!
  const _component_UserIconMobile = _resolveComponent("UserIconMobile")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MenuIcon, {
        onClick: _ctx.openMobMenu,
        class: "header__burger-icon"
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createVNode(_component_Logo, { class: "svg" }),
          _createVNode(_component_LogoWhite, { class: "header__logo-how-it-works" })
        ]),
        _: 1
      })
    ]),
    (this.translate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_router_link, { to: "/how-it-works" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.translateData.how_it_works), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Lang),
          (_ctx.getUserData != null)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "header__user",
                to: "/dashboard/home"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UserIcon, { class: "header__user-icon" }),
                  _createTextVNode(" " + _toDisplayString(this.name.name), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_link, {
      to: "/dashboard/home",
      class: "header__user-mob"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UserIconMobile, { class: "header__user-mob-icon" })
      ]),
      _: 1
    }),
    (_ctx.mobileMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_CloseIcon, {
              onClick: _ctx.closeMobMenu,
              class: "header__mob-menu-close"
            }, null, 8, ["onClick"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_link, {
                class: "header__mob-menu-link",
                to: "/how-it-works"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.translateData.how_it_works), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Lang, { type: 'button' })
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}