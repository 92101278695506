<template>
  <div
    class="login"
    v-if="translateData"
  >
    <div class="card card-container">
      <Form
        @submit="handleLogin"
        :validation-schema="schema"
      >
        <div class="form-group">
          <Field
            name="email"
            type="text"
            placeholder="Email"
            class="form-control"
          />
          <ErrorMessage
            name="email"
            class="error-feedback"
          />
        </div>
        <div class="form-group">
          <Field
            name="password"
            type="password"
            placeholder="Password"
            class="form-control password"
          />
          <ErrorMessage
            name="password"
            class="error-feedback"
          />
          <AlertBlock :message="message" />
        </div>
        <div class="form-group login__recaptcha">
          <vue-recaptcha
            ref="recaptcha"
            @error="errorRecaptcha"
            @verify="verifyRecaptcha"
            sitekey="6LfxzyIeAAAAAGfS5I9ZUq9Rt0eAnrYLlOqkHIOV"
          />
        </div>

        <div class="form-group">
          <button class="login__button">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>{{ this.translateData.to_dashboard }}</span>
          </button>
        </div>

        <router-link
          to="/password-forgot"
          class="login__forgot-password"
        >
          {{ this.translateData.forgot_password_button }}
        </router-link>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { VueRecaptcha } from 'vue-recaptcha'

import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertBlock,
    VueRecaptcha

  },
  data () {
    const schema = yup.object().shape({
      email: yup.string().required('Login is required!'),
      password: yup.string().required('Password is required!')
    })

    return {
      translateData: {},
      loading: false,
      message: '',
      schema,
      recaptcha: false
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('to_dashboard', 'forgot_password_button')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    verifyRecaptcha () {
      this.recaptcha = true
    },
    errorRecaptcha () {
      this.recaptcha = false
    },
    handleLogin (user) {
      this.loading = true
      this.$store.dispatch('auth/login', user).then(
        (res) => {
          if (res.data.email_verified === false) {
            this.$router.push('/mail-not-confirmed')
          } else this.$router.push('/dashboard/home')
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    }
  }
})
</script>

<style lang="scss" scoped>
.login {
  &__button {
    border-radius: 5px;
    background: #272AE5;
    color: #ffffff;
    width: 400px;
    height: 48px;
    margin: 10px 0 0 0;
    overflow: hidden;
    border: none;
    font-weight: 700;
    letter-spacing: 0.32px;

    &:hover {
      border-radius: 15px;
      border: 2px solid #272AE5;
      background: #fff;
      color: #25262C;
      cursor: pointer;
    }
    &:active {
    }
  }

  &__forgot-password {
    font-size: 16px;
    color: #272AE5;
    display: flex;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__recaptcha {
    padding-top: 10px;
  }
}
.password {
  margin-top: 20px;
}
.form-group {
  position: relative;
  padding-bottom: 10px;
}
.error-feedback {
  color: #ff444f;
  font-size: 12px;
  position: absolute;
  bottom: -7px;
  left: 20px;
}

@media (max-width: $break-sm) {
  .login {
    &__button {
      width: 100%;
    }
  }
  .blur {
    width: 100%;
  }
  .form-group {
    position: relative;
  }
}
</style>
