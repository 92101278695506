import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2da832ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "statistic-block__container"
}
const _hoisted_2 = {
  key: 0,
  class: "statistic-block"
}
const _hoisted_3 = { class: "statistic-block__item" }
const _hoisted_4 = { class: "statistic-block__item-icon" }
const _hoisted_5 = { class: "statistic-block__content" }
const _hoisted_6 = { class: "statistic-block__title" }
const _hoisted_7 = { class: "statistic-block__text" }
const _hoisted_8 = { class: "statistic-block__item" }
const _hoisted_9 = { class: "statistic-block__item-icon" }
const _hoisted_10 = { class: "statistic-block__content" }
const _hoisted_11 = { class: "statistic-block__title" }
const _hoisted_12 = { class: "statistic-block__text" }
const _hoisted_13 = { class: "statistic-block__item" }
const _hoisted_14 = { class: "statistic-block__item-icon" }
const _hoisted_15 = { class: "statistic-block__content" }
const _hoisted_16 = { class: "statistic-block__title" }
const _hoisted_17 = { class: "statistic-block__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Group = _resolveComponent("Group")!
  const _component_Win = _resolveComponent("Win")!
  const _component_Withdraw = _resolveComponent("Withdraw")!

  return (_ctx.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (this.translateData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Group)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(this.translateData.total_people), 1),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.statistics.users || 0), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_Win)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(this.translateData.total_deposited), 1),
                  _createElementVNode("div", _hoisted_12, "$" + _toDisplayString(_ctx.statistics.money_in || 0), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_Withdraw)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(this.translateData.total_withdrawn), 1),
                  _createElementVNode("div", _hoisted_17, "$" + _toDisplayString(_ctx.statistics.money_out || 0), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}