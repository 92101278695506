<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.home-section__top-section-right .svg {
  display: none;
}
.form-control {
  border-radius: 5px;
  border: 1px solid #b2b4b9;
  width: 400px;
  height: 48px;
  padding-left: 10px !important;
  outline: none;
}
.error-feedback {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #ff444f;
}

@media (max-width: $break-sm) {
  .form-control {
    width: 100%;
    height: 40px;
  }
}
</style>
